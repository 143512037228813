import styled from "styled-components";
import FrameComponent from "./FrameComponent";

const FrameChild = styled.img`
  height: 0px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: contain;
`;
const ReasonsForProcessingPDInner = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-10xs);
  box-sizing: border-box;
  max-width: 100%;
`;
const Div = styled.div`
  width: 653.5px;
  position: relative;
  font-weight: 300;
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  padding-left: var(--padding-xl);
  padding-right: var(--padding-xl);
`;
const CourtOrderOrOtherAuthorityChild = styled.div`
  height: 103.2px;
  width: 1248px;
  position: relative;
  background-color: var(--background);
  border-bottom: 1px solid var(--main-black);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
`;
const Div1 = styled.div`
  flex: 1;
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 406px;
  max-width: 100%;
  z-index: 1;
  @media screen and (max-width: 450px) {
    min-width: 100%;
  }
`;
const Input = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  font-family: var(--footer);
  font-size: var(--footer-size);
  background-color: transparent;
  height: 19px;
  flex: 1;
  position: relative;
  color: var(--color-black);
  text-align: center;
  display: inline-block;
  min-width: 406px;
  max-width: 100%;
  z-index: 2;
  @media screen and (max-width: 450px) {
    min-width: 100%;
  }
`;
const CourtOrderOrOtherAuthority = styled.div`
  align-self: stretch;
  background-color: var(--background);
  border-bottom: 1px solid var(--main-black);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-24xl) 0px var(--padding-22xl);
  max-width: 100%;
  row-gap: 20px;
  text-align: center;
`;
const OrganizationProcessingPD = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px 0px;
  max-width: 100%;
`;
const Div2 = styled.div`
  width: 596.2px;
  position: relative;
  font-weight: 300;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
`;
const Div3 = styled.div`
  flex: 1;
  position: relative;
  font-weight: 300;
  display: inline-block;
  min-width: 387px;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    min-width: 100%;
  }
`;
const Parent1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-10xs);
  box-sizing: border-box;
  gap: 0px 55px;
  max-width: 100%;
  @media screen and (max-width: 750px) {
    gap: 0px 55px;
  }
`;
const JudicialActsHandlerChild = styled.img`
  align-self: stretch;
  height: 0px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  z-index: 4;
`;
const DataRetentionRules = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px 55px;
  max-width: 100%;
  @media screen and (max-width: 750px) {
    gap: 0px 55px;
  }
`;
const JudicialActsHandler = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-10xs);
  box-sizing: border-box;
  gap: 27px 0px;
  max-width: 100%;
`;
const Group = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-10xs);
  box-sizing: border-box;
  gap: 0px 55px;
  max-width: 100%;
  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 750px) {
    gap: 0px 55px;
  }
`;
const FrameItem = styled.img`
  height: 0px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: contain;
  z-index: 4;
`;
const P = styled.p`
  margin: 0;
`;
const Div4 = styled.div`
  height: 190px;
  width: 596.2px;
  position: relative;
  font-weight: 300;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
`;
const Parent2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-5xs);
  box-sizing: border-box;
  gap: 0px 55px;
  max-width: 100%;
  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 750px) {
    gap: 0px 55px;
  }
`;
const Div5 = styled.div`
  width: 613.5px;
  height: 209px;
  position: relative;
  font-weight: 300;
  display: inline-block;
`;
const Li = styled.li`
  margin-bottom: 12px;
`;
const Li1 = styled.li``;
const Ul = styled.ul`
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-2xl);
`;
const Div6 = styled.div`
  width: 613.5px;
  position: relative;
  font-weight: 300;
  display: inline-block;
`;
const Div7 = styled.div`
  width: 613.5px;
  height: 266px;
  position: relative;
  font-weight: 300;
  display: inline-block;
`;
const Div8 = styled.div`
  width: 613.5px;
  height: 830px;
  position: relative;
  font-weight: 300;
  display: inline-block;
`;
const RightsOfSubjects = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-xl) var(--padding-10xs);
  box-sizing: border-box;
  gap: 16px 0px;
  max-width: 100%;
`;
const Div9 = styled.div`
  align-self: stretch;
  position: relative;
  font-weight: 500;
`;
const Div10 = styled.div`
  align-self: stretch;
  position: relative;
  font-weight: 300;
`;
const Div11 = styled.div`
  width: 613.5px;
  height: 214px;
  position: relative;
  font-weight: 300;
  display: inline-block;
`;
const Parent3 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0px;
`;
const Div12 = styled.div`
  width: 613.5px;
  height: 171px;
  position: relative;
  font-weight: 300;
  display: inline-block;
`;
const FormalDocumentation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-base);
  gap: 16px 0px;
`;
const Div13 = styled.div`
  width: 613.5px;
  height: 228px;
  position: relative;
  font-weight: 300;
  display: inline-block;
`;
const ConsentWithdrawal = styled.div`
  width: 654px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px var(--padding-xl);
  box-sizing: border-box;
  gap: 16px 0px;
  max-width: 100%;
`;
const ReasonsForProcessingPD = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 29px 0px;
  max-width: 100%;
`;
const DataSubjectConsentsRoot = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-11xs) 0px 0px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--footer-size);
  color: var(--color-black);
  font-family: var(--footer);
  @media screen and (max-width: 450px) {
    display: none;
  }
`;

const DataSubjectConsents = () => {
  return (
    <DataSubjectConsentsRoot>
      <ReasonsForProcessingPD>
        <ReasonsForProcessingPDInner>
          <FrameChild loading="lazy" alt="" src="/vector-435.svg" />
        </ReasonsForProcessingPDInner>
        <OrganizationProcessingPD>
          <Div>
            3.5. Общество обрабатывает персональные данные только при наличии
            хотя бы одного из условий ниже в течение следующих сроков:
          </Div>
          <CourtOrderOrOtherAuthority>
            <CourtOrderOrOtherAuthorityChild />
            <Div1>Правовое основание обработки персональных данных</Div1>
            <Input
              placeholder="Срок обработки и хранения персональных данных"
              type="text"
            />
          </CourtOrderOrOtherAuthority>
        </OrganizationProcessingPD>
        <FrameComponent
          prop="С согласия субъекта персональных данных на обработку его персональных данных"
          prop1="В течение срока, на который было дано согласие на обработку персональных данных"
          vector437="/vector-437.svg"
        />
        <FrameComponent
          prop="Для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления и выполнения возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей"
          prop1="В течение срока, установленного соответствующими международными договорами или законами"
          vector437="/vector-438.svg"
        />
        <Parent1>
          <Div2>
            При необходимости обработки персональных данных, подлежащих
            опубликованию или обязательному раскрытию в соответствии
            с федеральным законом
          </Div2>
          <Div3>В течение срока, установленного соответствующими законами</Div3>
        </Parent1>
        <JudicialActsHandler>
          <JudicialActsHandlerChild alt="" src="/vector-439.svg" />
          <DataRetentionRules>
            <Div2>
              Для исполнения судебного акта, акта другого органа или
              должностного лица, подлежащих исполнению в соответствии
              с законодательством Российской Федерации об исполнительном
              производстве
            </Div2>
            <Div3>
              В течение срока, необходимо для исполнения соответствующего акта
            </Div3>
          </DataRetentionRules>
          <JudicialActsHandlerChild alt="" src="/vector-440.svg" />
        </JudicialActsHandler>
        <FrameComponent
          prop="В связи с участием лица в конституционном, гражданском, административном, уголовном судопроизводстве, судопроизводстве в арбитражных судах"
          prop1="В течение срока участия в соответствующем судопроизводстве, включая сроки обжалования (оспаривания) судебных актов, кроме случаев, когда более длительный срок обработки персональных данных установлен действующим законодательством Российской Федерации"
          vector437="/vector-441.svg"
        />
        <Group>
          <Div2>
            Для исполнения договора, стороной которого либо выгодоприобретателем
            или поручителем по которому является субъект персональных данных,
            а также для заключения договора по инициативе субъекта персональных
            данных или договора, по которому субъект персональных данных будет
            являться выгодоприобретателем или поручителем
          </Div2>
          <Div2>
            В течение срока действия такого договора, кроме случаев, когда более
            длительный срок обработки персональных данных установлен действующим
            законодательством Российской Федерации
          </Div2>
        </Group>
        <ReasonsForProcessingPDInner>
          <FrameItem alt="" src="/vector-441.svg" />
        </ReasonsForProcessingPDInner>
        <Group>
          <Div2>
            Для защиты жизни, здоровья или иных жизненно важных интересов
            субъекта персональных данных, если получение согласия субъекта
            персональных данных невозможно
          </Div2>
          <Div2>
            До момента, когда получение согласия субъекта персональных данных
            станет возможным или когда соответствующие основания, угрожающие
            жизни, здоровью или иным жизненно важным интересам, отпадут
            (в зависимости от того, какое обстоятельство наступит раньше)
          </Div2>
        </Group>
        <ReasonsForProcessingPDInner>
          <FrameItem alt="" src="/vector-441.svg" />
        </ReasonsForProcessingPDInner>
        <Parent2>
          <Div2>
            Для осуществления прав и законных интересов оператора или третьих
            лиц при условии, что при этом не нарушаются права и свободы субъекта
            персональных данных
          </Div2>
          <Div4>
            <P>
              В течение срока, необходимого для осуществления прав и обеспечения
              законных интересов
            </P>
            <P></P>
            <P>
              Конкретный срок определяется Обществом с учетом положений
              настоящей Политики, внутренних документов и локальных нормативных
              актов Общества, а также принципов обработки персональных данных
              и требований действующего законодательства Российской Федерации,
              в том числе в части прекращения обработки персональных данных при
              достижении конкретных, заранее определенных и законных целей такой
              обработки
            </P>
          </Div4>
        </Parent2>
        <ReasonsForProcessingPDInner>
          <FrameChild loading="lazy" alt="" src="/vector-440.svg" />
        </ReasonsForProcessingPDInner>
        <RightsOfSubjects>
          <Div5>
            <P>
              3.6. Общество вправе поручить обработку персональных данных
              третьим лицам — обработчикам — на основании заключаемых с этими
              лицами договоров. К таким лицам, в частности, относятся поставщики
              услуг, которые помогают Обществу в его деятельности: поставщики
              услуг хостинга, контактного центра по работе с клиентами и т. д.
            </P>
            <P></P>
            <P>
              Обработчики обязуются соблюдать принципы и правила обработки
              персональных данных, предусмотренные Федеральным законом № 152-ФЗ
              «О персональных данных» (включая ст. 18.1 и ч. 5 ст. 18), иными
              законами и подзаконными актами. Для каждого обработчика в договоре
              будут определены:
            </P>
          </Div5>
          <Div6>
            <Ul>
              <Li>перечень обрабатываемых персональных данных;</Li>
              <Li>цели их обработки;</Li>
              <Li>
                перечень действий (операций), которые будут совершаться
                с персональными данными обработчиком;
              </Li>
              <Li>
                обязанности обработчика по соблюдению конфиденциальности
                и обеспечению безопасности персональных данных при их обработке,
                а также перечень принимаемых обработчиком мер по обеспечению
                защиты обрабатываемых им персональных данных, включая требование
                об уведомлении Общества об инцидентах с персональными данными;
              </Li>
              <Li1>
                обязанность по запросу Общества в течение срока действия
                поручения на обработку персональных данных предоставлять
                Обществу документы и иную информацию, подтверждающие принятие
                мер и соблюдение в целях исполнения поручения Общества
                требований, установленных Федеральным законом № 152-ФЗ.
              </Li1>
            </Ul>
          </Div6>
          <Div7>
            <P>
              Обработчик не обязан получать согласие субъекта персональных
              данных на обработку его персональных данных. Если для обработки
              персональных данных по поручению Общества необходимо получение
              согласия субъекта персональных данных, такое согласие получает
              непосредственно Общество.
            </P>
            <P></P>
            <P>
              3.7. В случаях, установленных законодательством Российской
              Федерации, Общество вправе осуществлять передачу персональных
              данных третьим лицам, в том числе без поручения таким лицам
              обработки персональных данных.
            </P>
            <P></P>
            <P>
              3.8. Если иное не предусмотрено законодательством Российской
              Федерации, Общество прекращает обработку персональных данных
              (в отношении любой из заявленных выше целей) и уничтожает
              их в случаях:
            </P>
          </Div7>
          <Div6>
            <Ul>
              <Li>ликвидации Общества;</Li>
              <Li>
                реорганизации Общества, влекущей прекращение его деятельности;
              </Li>
              <Li1>
                отпадения правовых оснований обработки персональных данных и/или
                достижения целей обработки персональных данных.
              </Li1>
            </Ul>
          </Div6>
          <Div6>
            <P>
              Конкретный порядок уничтожения персональных данных на носителях,
              содержащих персональные данные, в том числе внешних/съемных
              электронных носителях, бумажных носителях и в информационных
              системах персональных данных, определяются Обществом в своих
              внутренних документах и локальных нормативных актах.
            </P>
            <P></P>
            <P>
              3.9. Общество может собирать техническую информацию, когда
              пользователь посещает веб-сайты или использует мобильные
              приложения и услуги Общества. Сюда входит такая информация, как
              IP-адрес, тип используемого мобильного устройства, операционная
              система устройства и тип браузера, уникальный идентификатор
              устройства, адрес ссылающихся веб-сайтов, путь, по которому
              пользователь проходит через веб-сайты и мобильные приложения
              Общества, и так далее. Общество может также использовать такие
              технологии, как файлы cookie, веб-маяки и идентификаторы мобильных
              устройств, для сбора информации об использовании веб-сайтов
              и мобильных сервисов Общества. Файлы cookie позволяют Обществу
              предоставлять пользователям соответствующую информацию по мере
              использования ими веб-сайтов и мобильных сервисов Общества
              (например, открывать и загружать соответствующие страницы).
              Веб-маяки позволяют узнавать, была ли посещена определенная
              страница, было ли открыто электронное письмо или были ли
              эффективны рекламные баннеры на веб-сайтах Общества и других
              сайтах.
            </P>
            <P></P>
            <P>
              Общество использует данную информацию для обеспечения
              работоспособности своих веб-сайтов и мобильных приложений, для
              повышения качества оказываемых услуг, исправления ошибок
              и улучшения пользовательского опыта в целом. При этом Общество
              не преследует цели идентифицировать конкретного пользователя
              веб-сайтов, мобильных приложений и услуг Общества.
            </P>
            <P></P>
            <P>
              3.10. Общество при осуществлении обработки персональных данных:
            </P>
          </Div6>
          <Div8>
            <Ul>
              <Li>
                принимает меры, необходимые и достаточные для обеспечения
                выполнения требований законодательства Российской Федерации,
                внутренних документов и локальных нормативных актов Общества
                в области персональных данных;
              </Li>
              <Li>
                принимает правовые, организационные и технические меры для
                защиты персональных данных от неправомерного или случайного
                доступа к ним, уничтожения, изменения, блокирования,
                копирования, предоставления, распространения персональных
                данных, а также от иных неправомерных действий в отношении
                персональных данных;
              </Li>
              <Li>
                назначает лицо, ответственное за организацию обработки
                персональных данных в Обществе;
              </Li>
              <Li>
                издает внутренние документы, определяющие политику Общества
                в отношении обработки персональных данных, локальные акты
                по вопросам обработки персональных данных, а также локальные
                акты, устанавливающие процедуры, направленные на предотвращение
                и выявление нарушений законодательства Российской Федерации,
                устранение последствий таких нарушений;
              </Li>
              <Li>
                осуществляет ознакомление работников Общества, его филиалов,
                представительств и структурных подразделений, непосредственно
                осуществляющих обработку персональных данных, с положениями
                законодательства Российской Федерации, внутренних документов
                и локальных нормативных актов Общества в области персональных
                данных, в том числе с требованиями к защите персональных данных,
                и обучение указанных работников;
              </Li>
              <Li>
                проводит регулярные обязательные тренинги для своих работников
                по вопросам персональных данных;
              </Li>
              <Li>
                осуществляет внутренний контроль и (или) аудит соответствия
                обработки персональных данных требованиям законодательства
                Российской Федерации и принятым в соответствии с ним нормативным
                правовым актам, иным требованиям к защите персональных данных,
                настоящей Политике, внутренним документам и локальным
                нормативным актам Общества в области персональных данных;
              </Li>
              <Li>
                публикует или иным образом обеспечивает неограниченный доступ
                к настоящей Политике;
              </Li>
              <Li>
                прекращает обработку персональных данных и уничтожает
                их в случаях, предусмотренных законодательством Российской
                Федерации;
              </Li>
              <Li1>
                совершает иные действия, предусмотренные законодательством
                Российской Федерации в области персональных данных.
              </Li1>
            </Ul>
          </Div8>
        </RightsOfSubjects>
        <ConsentWithdrawal>
          <Div9>4. Права субъекта персональных данных</Div9>
          <Parent3>
            <Div10>
              Лицо, персональные данные которого обрабатываются Обществом,
              имеет:
            </Div10>
            <Div11>
              <Ul>
                <Li>
                  право на отзыв ранее данного им согласия на обработку
                  персональных данных*;
                </Li>
                <Li>
                  право на получение информации, касающейся обработки
                  персональных данных;
                </Li>
                <Li1>
                  право требовать уточнения своих персональных данных,
                  их блокирования или уничтожения, если персональные данные
                  являются неполными, устаревшими, недостоверными, незаконно
                  полученными, не являются необходимыми для заявленной цели
                  обработки, а также требовать прекращения обработки
                  персональных данных, если цель такой обработки достигнута
                  Обществом.
                </Li1>
              </Ul>
            </Div11>
          </Parent3>
          <FormalDocumentation>
            <Div12>
              <P>
                * Согласно ч. 2 ст. 9, ч. 4 и 5 ст. 21 Федерального закона
                «О персональных данных» Общество вправе продолжить обработку
                персональных данных при наличии иных правовых оснований.
              </P>
              <P></P>
              <P>
                Если иной порядок взаимодействия Общества и субъекта
                персональных данных не предусмотрен соответствующим документом
                между ними (например, договором или текстом согласия
                на обработку персональных данных), для реализации указанных прав
                субъекту персональных данных необходимо направить Обществу
                заявление:
              </P>
            </Div12>
            <Div6>
              <Ul>
                <Li>
                  в письменной форме и подписанное собственноручной подписью —
                  по адресу 123112, Россия, г. Москва, Пресненская наб., д. 10,
                  эт. 41, пом. I, ком. 6; либо
                </Li>
                <Li>
                  в виде электронного документа и подписанное электронной
                  подписью — на электронную почту penat.partners@gmail.com.
                </Li>
              </Ul>
              <P>&nbsp;</P>
              <P>
                Такое заявление должно в обязательном порядке содержать описание
                требований субъекта персональных данных, а также следующие
                сведения:
              </P>
              <P></P>
              <Ul>
                <Li>ФИО субъекта персональных данных;</Li>
                <Li>
                  номер основного документа, удостоверяющего личность субъекта
                  персональных данных или его представителя, сведения о дате
                  выдачи указанного документа и выдавшем его органе ЛИБО иные
                  данные, позволяющие однозначно идентифицировать субъекта
                  персональных данных;
                </Li>
                <Li>
                  сведения, подтверждающие участие субъекта персональных данных
                  в отношениях с Обществом, ЛИБО сведения, иным способом
                  подтверждающие факт обработки персональных данных Обществом;
                </Li>
                <Li1>
                  подпись субъекта персональных данных или его представителя.
                </Li1>
              </Ul>
            </Div6>
            <Div6>
              Субъект персональных данных также вправе обжаловать действия
              (бездействия) и решения Общества, нарушающие его права при
              обработке персональных данных, в уполномоченный орган по защите
              прав субъектов персональных данных (Роскомнадзор) и в суд
              в порядке, установленном законодательством Российской Федерации.
            </Div6>
          </FormalDocumentation>
          <Parent3>
            <Div9>
              5. Сведения о реализуемых требованиях к защите персональных данных
            </Div9>
            <Div13>
              <P>
                Общество при обработке персональных данных принимает необходимые
                правовые, организационные и технические меры для защиты
                персональных данных от неправомерного или случайного доступа
                к ним, уничтожения, изменения, блокирования, копирования,
                предоставления, распространения персональных данных, а также
                от иных неправомерных действий в отношении персональных данных.
                Общество регулярно пересматривает и актуализирует принимаемые
                меры для обеспечения наилучшей защищенности обрабатываемых
                персональных данных — такие меры описываются в настоящей
                Политике, внутренних документах и локальных нормативных актах
                Общества.
              </P>
              <P></P>
              <P>К таким мерам, в частности, относится:</P>
            </Div13>
          </Parent3>
          <Div6>
            <Ul>
              <Li>разработка моделей угроз;</Li>
              <Li>
                определение угроз безопасности персональных данных при
                их обработке в информационных системах персональных данных;
              </Li>
              <Li>
                применение организационных и технических мер по обеспечению
                безопасности персональных данных при их обработке
                в информационных системах персональных данных, необходимых для
                выполнения требований к защите персональных данных, исполнение
                которых обеспечивает установленные Правительством РФ уровни
                защищенности персональных данных;
              </Li>
              <Li>
                применение прошедших в установленном порядке процедуру оценки
                соответствия средств защиты информации;
              </Li>
              <Li>
                оценка эффективности принимаемых мер по обеспечению безопасности
                персональных данных до ввода в эксплуатацию информационной
                системы персональных данных;
              </Li>
              <Li>
                обнаружение фактов несанкционированного доступа к персональным
                данным и принятием мер;
              </Li>
              <Li>
                восстановление персональных данных, модифицированных или
                уничтоженных вследствие несанкционированного доступа к ним;
              </Li>
              <Li>
                установление правил доступа к персональным данным,
                обрабатываемым в информационной системе персональных данных,
                а также обеспечением регистрации и учета всех действий,
                совершаемых с персональными данными в информационной системе
                персональных данных;
              </Li>
              <Li>
                контроль за принимаемыми мерами по обеспечению безопасности
                персональных данных и уровня защищенности информационных систем
                персональных данных;
              </Li>
              <Li>учет машинных носителей персональных данных;</Li>
              <Li>
                организация пропускного и внутриобъектового режимов
                на территории Общества;
              </Li>
              <Li>
                размещение технических средств обработки персональных данных
                в пределах охраняемой территории;
              </Li>
              <Li>
                поддержание технических средств охраны, сигнализации
                в постоянной готовности;
              </Li>
              <Li1>
                проведение мониторинга действий пользователей, проведение
                разбирательств по фактам нарушения требований безопасности
                персональных данных.
              </Li1>
            </Ul>
          </Div6>
        </ConsentWithdrawal>
      </ReasonsForProcessingPD>
    </DataSubjectConsentsRoot>
  );
};

export default DataSubjectConsents;
