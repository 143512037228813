import styled from "styled-components";

const Div = styled.div`
  align-self: stretch;
  position: relative;
  font-weight: 500;
`;
const I = styled.p`
  margin: 0;
  margin-bottom: 15px;
`;
const I6Container = styled.div`
  width: 613.5px;
  position: relative;
  font-weight: 300;
  display: inline-block;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;
const Text1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0px;
`;
const Div1 = styled.div`
  width: 613.5px;
  height: 722px;
  position: relative;
  font-weight: 300;
  display: inline-block;
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 1280px;
  }
`;
const Li = styled.li`
  margin-bottom: 12px;
`;
const Ol = styled.ol`
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-2xl);
`;
const Div2 = styled.div`
  width: 613.5px;
  height: 932px;
  position: relative;
  font-weight: 300;
  display: inline-block;
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 1532px;
  }
`;
const Div3 = styled.div`
  width: 613.5px;
  height: 932px;
  position: relative;
  font-weight: 300;
  display: inline-block;
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 2232px;
  }
`;
const Div4 = styled.div`
  width: 613.5px;
  height: 932px;
  position: relative;
  font-weight: 300;
  display: inline-block;
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 952px;
  }
`;
const Div5 = styled.div`
  width: 613.5px;
  height: 932px;
  position: relative;
  font-weight: 300;
  display: inline-block;
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 2252px;
  }
`;
const Div6 = styled.div`
  width: 613.5px;
  height: auto;
  position: relative;
  font-weight: 300;
  display: inline-block;
  @media screen and (max-width: 450px) {
    height: 932px;
    width: 613.5px;
    width: 100%;
    height: auto;
  }
`;
const HeaderFrameRoot = styled.div`
  width: 655px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  box-sizing: border-box;
  gap: 31px 0px;
  max-width: 100%;
  text-align: left;
  font-size: var(--footer-size);
  color: var(--color-black);
  font-family: var(--footer);
  padding: 0px 20px var(--padding-5xs) 20px;
  @media screen and (max-width: 450px) {
    width: 655px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    box-sizing: border-box;
    gap: 31px 0px;
    max-width: 100%;
    text-align: left;
    font-size: var(--footer-size);
    color: var(--color-black);
    font-family: var(--footer);
    padding: 0px 20px var(--padding-5xs) 20px;
  }
`;

const HeaderFrameMobile = () => {
  return (
    <HeaderFrameRoot>
      <Text1>
        <Div>1. Назначение и область действия документа</Div>
        <I6Container>
          <I>
            1.1. Политика Пенат (далее по тексту также — «Общество») в отношении
            обработки персональных данных (далее по тексту также — «Политика»)
            определяет позицию и намерения Общества в области обработки и защиты
            персональных данных, с целью соблюдения и защиты прав и свобод
            каждого человека и, в особенности, права на неприкосновенность
            частной жизни, личную и семейную тайну, защиту своей чести и доброго
            имени.
          </I>
          <I></I>
          <I>
            1.2. Политика неукоснительно исполняется руководителями
            и работниками всех структурных подразделений, филиалов
            и представительств Общества.
          </I>
          <I></I>
          <I>
            1.3. Действие Политики распространяется на все персональные данные
            субъектов, обрабатываемые в Обществе с применением средств
            автоматизации и без применения таких средств.
          </I>
          <I></I>
          <I>
            1.4. К настоящей Политике имеет доступ любой субъект персональных
            данных, в том числе с использованием сети «Интернет».
          </I>
          <I></I>
          <I>
            1.5. Общество периодически актуализирует настоящую Политику и вправе
            в одностороннем порядке в любой момент изменять ее условия. Общество
            рекомендует регулярно проверять содержание настоящей Политики
            на предмет ее возможных изменений.
          </I>
          <I></I>
          <I>
            Если иное не предусмотрено Политикой, все вносимые в нее изменения
            вступают в силу с даты, указанной в Политике.
          </I>
          <I></I>
          <I>
            1.6. Во всем ином, что не предусмотрено настоящей Политикой,
            Общество руководствуется положениями действующего законодательства
            Российской Федерации.
          </I>
        </I6Container>
      </Text1>
      <Text1>
        <Div>2. Термины</Div>
        <Div6>
          <I>
            2.1. Персональные данные — любая информация, относящаяся к прямо или
            косвенно определенному или определяемому физическому лицу (субъекту
            персональных данных). К такой информации, в частности, можно
            отнести: ФИО, год, месяц, дата и место рождения, адрес, сведения
            о семейном, социальном, имущественном положении, сведения
            об образовании, профессии, доходах, а также другую информацию,
            позволяющую по совокупности определить (идентифицировать) субъекта
            персональных данных.
          </I>
          <I></I>
          <I>
            2.2. Обработка персональных данных — любое действие (операция) или
            совокупность действий (операций), совершаемых с использованием
            средств автоматизации или без использования таких средств
            с персональными данными, включая сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение персональных
            данных.
          </I>
          <I></I>
          <I>
            2.3. Субъект персональных данных — физическое лицо, чьи персональные
            данные обрабатываются.
          </I>
          <I></I>
          <I>
            2.4. Оператор — лицо, самостоятельно или совместно с иными лицами
            организующее и (или) осуществляющее обработку персональных данных,
            а также определяющие цели обработки персональных данных, состав
            персональных данных, подлежащих обработке, действия (операции),
            совершаемые с персональными данными. Для целей настоящей Политики
            Общество, обрабатывая персональные данные, является оператором, если
            иное прямо не указано в Политике.
          </I>
          <I></I>
          <I>
            2.5. Обработчик — любое лицо, которое на основании договора
            с оператором осуществляет обработку персональных данных по поручению
            такого оператора, действуя от имени и (или) в интересах последнего
            при обработке персональных данных. Оператор несет ответственность
            перед субъектом персональных данных за действия или бездействия
            обработчика. Обработчик несет ответственность перед оператором.
          </I>
          <I></I>
          <I>
            2.6. Прочие термины используются в настоящей Политике в соответствии
            со значениями, определяемыми действующим законодательством
            Российской Федерации, если иное прямо не указано в Политике.
          </I>
        </Div6>
      </Text1>
      <Text1>
        <Div>3. Порядок и условия обработки персональных данных</Div>
        <Div6>
          <I>
            3.1. Под безопасностью персональных данных Общество понимает
            защищенность персональных данных от неправомерного или случайного
            доступа к ним, уничтожения, изменения, блокирования, копирования,
            предоставления, распространения персональных данных, а также от иных
            неправомерных действий в отношении персональных данных и принимает
            необходимые правовые, организационные и технические меры для защиты
            персональных данных.
          </I>
          <I></I>
          <I>
            3.2. Обработка и обеспечение безопасности персональных данных
            в Обществе осуществляется в соответствии с требованиями Конституции
            Российской Федерации, Федерального закона № 152-ФЗ «О персональных
            данных», подзаконных актов, других определяющих случаи и особенности
            обработки персональных данных законов Российской Федерации, а также
            руководящих и методических документов Правительства Российской
            Федерации, Минцифры России, Роскомнадзора, ФСТЭК России и ФСБ
            России.
          </I>
          <I></I>
          <I>
            3.3. При обработке персональных данных Общество придерживается
            следующих принципов:
          </I>
          <I></I>
          <Ol>
            <Li>законности и справедливости;</Li>
            <Li>
              ограничения обработки персональных данных достижением конкретных,
              заранее определенных и законных целей;
            </Li>
            <Li>
              недопущения обработки персональных данных, несовместимой с целями
              сбора персональных данных;
            </Li>
            <Li>
              недопущения объединения баз данных, содержащих персональные
              данные, обработка которых осуществляется в целях, несовместимых
              между собой;
            </Li>
            <Li>
              соответствия содержания и объема обрабатываемых персональных
              данных заявленным целям обработки;
            </Li>
            <Li>
              недопущения избыточности обрабатываемых персональных данных
              по отношению к заявленным целям обработки;
            </Li>
            <Li>
              обеспечения точности персональных данных, их достаточности,
              а в необходимых случаях и актуальности по отношению к целям
              обработки персональных данных, а также принятия мер по удалению
              или уточнению неполных или неточных данных;
            </Li>
            <Li>
              прозрачности обработки персональных данных: субъекту персональных
              данных может предоставляться соответствующая информация,
              касающаяся обработки его персональных данных;
            </Li>
            <Li>
              осуществления хранения персональных данных в форме, позволяющей
              определить субъекта персональных данных, не дольше, чем этого
              требуют заявленные цели обработки персональных данных.
            </Li>
          </Ol>
          <I>&nbsp;</I>
          <I>
            3.4. Общество обрабатывает персональные данные на следующих
            условиях:
          </I>
        </Div6>
      </Text1>
      <Text1>
        <Div>Цель обработки персональных данных</Div>
        <Div6>
          <I>
            Для осуществления Обществом предпринимательской деятельности
            в области электронной коммерции в качестве владельца агрегатора
            информации о товарах (услугах) и путем самостоятельной продажи
            товаров (оказания услуг) дистанционным способом,
            в т. ч. с использованием сети «Интернет»; оказания сопутствующих
            логистических услуг (организация доставки товаров и осуществление
            складских операций), а также иной обычной хозяйственной деятельности
            как юридического лица, в т. ч. путем рекламирования себя, своих
            сервисов и услуг, контроля качества использования своих сервисов
            и услуг третьими лицами, организации пропускного и внутриобъектового
            режимов, обеспечения безопасности посетителей на своей территории,
            взаимодействия с субъектами персональных данных, контрагентами
            и третьими лицами, в соответствии с действующим
            законодательством РФ, уставом Общества, внутренними документами
            и локальными нормативными актами Общества.Для осуществления
            Обществом предпринимательской деятельности в области электронной
            коммерции в качестве владельца агрегатора информации о товарах
            (услугах) и путем самостоятельной продажи товаров (оказания услуг)
            дистанционным способом, в т. ч. с использованием сети «Интернет»;
            оказания сопутствующих логистических услуг (организация доставки
            товаров и осуществление складских операций), а также иной обычной
            хозяйственной деятельности как юридического лица, в т. ч. путем
            рекламирования себя, своих сервисов и услуг, контроля качества
            использования своих сервисов и услуг третьими лицами, организации
            пропускного и внутриобъектового режимов, обеспечения безопасности
            посетителей на своей территории, взаимодействия с субъектами
            персональных данных, контрагентами и третьими лицами, в соответствии
            с действующим законодательством РФ, уставом Общества, внутренними
            документами и локальными нормативными актами Общества.
          </I>
          <I></I>
          <I>
            Для заключения и исполнения договоров с участием субъектов
            персональных данных в качестве сторон, выгодоприобретателей или
            поручителей по таким договорам, включая, но не ограничиваясь,
            предоставление возможности использовать сервисы и продукты Общества,
            выдачу призов и выплату вознаграждений по итогам конкурсов и акций
            и т. д.
          </I>
          <I></I>
          <I>
            Для оформления и регулирования трудовых отношений Общества
            с субъектами персональных данных и иных непосредственно связанных
            с ними отношений, в том числе для исполнения обязательств
            и реализации прав сторон в рамках трудовых договоров между ними,
            включая, но не ограничиваясь, для расчета и выплаты заработной
            платы, направления субъектов персональных данных в командировки, для
            обеспечения безопасности субъектов персональных данных, Общества
            и третьих лиц, для контроля количества и качества выполняемой работы
            и обеспечения сохранности имущества субъектов персональных данных,
            Общества и третьих лиц, для получения субъектами персональных данных
            различных гарантий и льгот и т. д.
          </I>
          <I></I>
          <I>
            Для соблюдения и исполнения требований действующего законодательства
            Российской Федерации, включая, не ограничиваясь, осуществление
            бухгалтерского и налогового учета, организацию документооборота
            и архивного хранения, направление соответствующих сведений
            в государственные органы, исполнение требований и предписаний
            государственных органов, исполнение судебных актов, рассмотрение
            претензий правообладателей и обращений субъектов персональных данных
            и т. д.
          </I>
          <I></I>
          <I>
            Для соблюдения и исполнения требований действующего законодательства
            Российской Федерации, включая, не ограничиваясь, осуществление
            бухгалтерского и налогового учета, организацию документооборота
            и архивного хранения, направление соответствующих сведений
            в государственные органы, исполнение требований и предписаний
            государственных органов, исполнение судебных актов, рассмотрение
            претензий правообладателей и обращений субъектов персональных данных
            и т. д.
          </I>
        </Div6>
      </Text1>
      <Text1>
        <Div>Категории субъектов персональных данных</Div>
        <Div6>
          <I>
            Стороны, выгодоприобретатели или поручители по договорам, включая,
            но не ограничиваясь, пользователей сервисов и продуктов Общества,
            физических лиц, оказывающих услуги Обществу, участников конкурсов,
            акций и т. д.; Пользователи веб-сайтов и иных онлайн-сервисов
            Общества; Представители контрагентов, государственных органов,
            субъектов персональных данных и третьих лиц; Посетители территории
            Общества; Иные субъекты персональных данных, каким-либо образом
            взаимодействующие с Обществом в рамках заявленной цели.
          </I>
          <I></I>
          <I>
            Стороны, выгодоприобретатели или поручители по договорам, включая,
            но не ограничиваясь, пользователей сервисов и продуктов Общества,
            физических лиц, оказывающих услуги Обществу, участников конкурсов,
            акций и т. д.; Пользователи веб-сайтов и иных онлайн-сервисов
            Общества.
          </I>
          <I></I>
          <I>
            Работники Общества; Близкие родственники работников Общества; Бывшие
            работники Общества; Кандидаты на замещение вакантных должностей
            Общества.
          </I>
          <I></I>
          <I>
            Стороны, выгодоприобретатели или поручители по договорам, включая,
            но не ограничиваясь, пользователей сервисов и продуктов Общества,
            физических лиц, оказывающих услуги Обществу, участников конкурсов,
            акций и т. д.; Пользователи веб-сайтов и иных онлайн-сервисов
            Общества; Представители контрагентов, государственных органов,
            субъектов персональных данных и третьих лиц; Посетители территории
            Общества; Работники Общества; Близкие родственники работников
            Общества; Бывшие работники Общества; Кандидаты на замещение
            вакантных должностей Общества; Иные субъекты персональных данных,
            каким-либо образом взаимодействующие с Обществом в рамках заявленной
            цели.
          </I>
        </Div6>
      </Text1>
      <Text1>
        <Div>Категории и перечень персональных данных</Div>
        <Div6>
          <I>
            Гражданство; ФИО; Пол; Дата и место рождения; Данные документов,
            удостоверяющих личность; Адрес регистрации по месту жительства
            и адрес фактического проживания; Контактная информация (номер
            телефона, факса, адрес электронной почты, почтовый адрес);
            Банковские реквизиты; Реквизиты доверенностей; ИНН, СНИЛС, ОГРНИП,
            идентификаторы пользователей сервисов и продуктов Общества; Сведения
            о транспортных средствах; Регистрационные и авторизационные данные
            (логин, пароль и т. д.), записи звонков (разговоров), технические
            сведения о пользовательских устройствах и идентификаторы, в т. ч.
            файлы cookies, информация о пользовательском местоположении,
            сведения о приобретенных товарах (услугах) и иные данные,
            самостоятельно предоставленные такими пользователями в адрес
            Общества (в отношении пользователей веб-сайтов и иных
            онлайн-сервисов Общества).
          </I>
          <I></I>
          <I>
            Гражданство; ФИО; Пол; Дата и место рождения; Данные документов,
            удостоверяющих личность; Сведения, содержащиеся в документах
            миграционного учета; Адрес регистрации по месту жительства и адрес
            фактического проживания; Контактная информация (номер телефона,
            факса, адрес электронной почты, почтовый адрес); Данные документов
            об образовании, квалификации, профессиональной подготовке, сведения
            о повышении квалификации и иные аналогичные данные; Семейное
            положение, сведения о составе семьи и близких родственниках, которые
            могут понадобиться Обществу, в том числе, но не ограничиваясь, для
            предоставления работнику льгот, предусмотренных трудовым и налоговым
            законодательством РФ; Сведения о воинском учете и сведения,
            содержащиеся в документах воинского учета; Сведения, содержащиеся
            в трудовой книжке, сведения о трудовом стаже, предыдущих местах
            работы, доходах с предыдущих мест работы; ИНН, СНИЛС; Банковские
            реквизиты; Информация о приеме, переводе, увольнении и иных
            событиях, относящихся к трудовой деятельности работника у Общества;
            Сведения о доходах у Общества; Сведения о деловых и иных личных
            качествах, носящие оценочный характер; Биографические сведения;
            Сведения о временной нетрудоспособности и о состоянии здоровья;
            Фото- и видеоизображения.
          </I>
          <I></I>
          <I>
            Гражданство; ФИО; Пол; Дата и место рождения; Данные документов,
            удостоверяющих личность; Сведения, содержащиеся в документах
            миграционного учета; Адрес регистрации по месту жительства и адрес
            фактического проживания; Контактная информация (номер телефона,
            факса, адрес электронной почты, почтовый адрес); Банковские
            реквизиты; Реквизиты доверенностей; ИНН, СНИЛС, ОГРНИП,
            идентификаторы пользователей сервисов и продуктов Общества; Сведения
            о транспортных средствах; Регистрационные и авторизационные данные
            (логин, пароль и т. д.), записи звонков (разговоров), технические
            сведения о пользовательских устройствах и идентификаторы, в т. ч.
            файлы cookies, информация о пользовательском местоположении,
            сведения о приобретенных товарах (услугах) и иные данные,
            самостоятельно предоставленные такими пользователями в адрес
            Общества (в отношении пользователей веб-сайтов и иных
            онлайн-сервисов Общества); Данные документов об образовании,
            квалификации, профессиональной подготовке, сведения о повышении
            квалификации и иные аналогичные данные; Семейное положение, сведения
            о составе семьи и близких родственниках, которые могут понадобиться
            Обществу, в том числе, но не ограничиваясь, для предоставления
            работнику льгот, предусмотренных трудовым и налоговым
            законодательством РФ; Сведения о воинском учете и сведения,
            содержащиеся в документах воинского учета; Сведения, содержащиеся
            в трудовой книжке, сведения о трудовом стаже, предыдущих местах
            работы, доходах с предыдущих мест работы; Информация о приеме,
            переводе, увольнении и иных событиях, относящихся к трудовой
            деятельности работника у Общества; Сведения о доходах у Общества;
            Биографические сведения; Сведения о временной нетрудоспособности
            и о состоянии здоровья.
          </I>
          <I></I>
          <I>
            3.5. Общество обрабатывает персональные данные только при наличии
            хотя бы одного из условий ниже в течение следующих сроков:
          </I>
        </Div6>
      </Text1>
      <Text1>
        <Div>Правовое основание обработки персональных данных</Div>
        <Div6>
          <I>
            С согласия субъекта персональных данных на обработку его
            персональных данных
          </I>
          <I></I>
          <I>
            Для достижения целей, предусмотренных международным договором
            Российской Федерации или законом, для осуществления и выполнения
            возложенных законодательством Российской Федерации на оператора
            функций, полномочий и обязанностей
          </I>
          <I></I>
          <I>
            При необходимости обработки персональных данных, подлежащих
            опубликованию или обязательному раскрытию в соответствии
            с федеральным законом
          </I>
          <I></I>
          <I>
            Для исполнения судебного акта, акта другого органа или должностного
            лица, подлежащих исполнению в соответствии с законодательством
            Российской Федерации об исполнительном производстве
          </I>
          <I></I>
          <I>
            В связи с участием лица в конституционном, гражданском,
            административном, уголовном судопроизводстве, судопроизводстве
            в арбитражных судах
          </I>
          <I></I>
          <I>
            Для исполнения договора, стороной которого либо выгодоприобретателем
            или поручителем по которому является субъект персональных данных,
            а также для заключения договора по инициативе субъекта персональных
            данных или договора, по которому субъект персональных данных будет
            являться выгодоприобретателем или поручителем
          </I>
          <I></I>
          <I>
            Для защиты жизни, здоровья или иных жизненно важных интересов
            субъекта персональных данных, если получение согласия субъекта
            персональных данных невозможно
          </I>
          <I></I>
          <I>
            Для осуществления прав и законных интересов оператора или третьих
            лиц при условии, что при этом не нарушаются права и свободы субъекта
            персональных данных
          </I>
        </Div6>
      </Text1>
      <Text1>
        <Div>Срок обработки и хранения персональных данных</Div>
        <Div6>
          <I>
            В течение срока, на который было дано согласие на обработку
            персональных данных
          </I>
          <I></I>
          <I>
            В течение срока, установленного соответствующими международными
            договорами или законами
          </I>
          <I></I>
          <I>В течение срока, установленного соответствующими законами</I>
          <I></I>
          <I>
            В течение срока, необходимо для исполнения соответствующего акта
          </I>
          <I></I>
          <I>
            В течение срока участия в соответствующем судопроизводстве, включая
            сроки обжалования (оспаривания) судебных актов, кроме случаев, когда
            более длительный срок обработки персональных данных установлен
            действующим законодательством Российской Федерации
          </I>
          <I></I>
          <I>
            В течение срока действия такого договора, кроме случаев, когда более
            длительный срок обработки персональных данных установлен действующим
            законодательством Российской Федерации
          </I>
          <I></I>
          <I>
            До момента, когда получение согласия субъекта персональных данных
            станет возможным или когда соответствующие основания, угрожающие
            жизни, здоровью или иным жизненно важным интересам, отпадут
            (в зависимости от того, какое обстоятельство наступит раньше)
          </I>
          <I></I>
          <I>
            В течение срока, необходимого для осуществления прав и обеспечения
            законных интересов Конкретный срок определяется Обществом с учетом
            положений настоящей Политики, внутренних документов и локальных
            нормативных актов Общества, а также принципов обработки персональных
            данных и требований действующего законодательства Российской
            Федерации, в том числе в части прекращения обработки персональных
            данных при достижении конкретных, заранее определенных и законных
            целей такой обработки
          </I>
          <I></I>
          <I>
            В течение срока, необходимого для осуществления прав и обеспечения
            законных интересов Конкретный срок определяется Обществом с учетом
            положений настоящей Политики, внутренних документов и локальных
            нормативных актов Общества, а также принципов обработки персональных
            данных и требований действующего законодательства Российской
            Федерации, в том числе в части прекращения обработки персональных
            данных при достижении конкретных, заранее определенных и законных
            целей такой обработки
          </I>
          <I></I>
          <I>
            3.6. Общество вправе поручить обработку персональных данных третьим
            лицам — обработчикам — на основании заключаемых с этими лицами
            договоров. К таким лицам, в частности, относятся поставщики услуг,
            которые помогают Обществу в его деятельности: поставщики услуг
            хостинга, контактного центра по работе с клиентами и т. д.
          </I>
          <I></I>
          <I>
            Обработчики обязуются соблюдать принципы и правила обработки
            персональных данных, предусмотренные Федеральным законом № 152-ФЗ
            «О персональных данных» (включая ст. 18.1 и ч. 5 ст. 18), иными
            законами и подзаконными актами. Для каждого обработчика в договоре
            будут определены:
          </I>
          <I></I>
          <I>
            перечень обрабатываемых персональных данных; цели их обработки;
            перечень действий (операций), которые будут совершаться
            с персональными данными обработчиком; обязанности обработчика
            по соблюдению конфиденциальности и обеспечению безопасности
            персональных данных при их обработке, а также перечень принимаемых
            обработчиком мер по обеспечению защиты обрабатываемых
            им персональных данных, включая требование об уведомлении Общества
            об инцидентах с персональными данными; обязанность по запросу
            Общества в течение срока действия поручения на обработку
            персональных данных предоставлять Обществу документы и иную
            информацию, подтверждающие принятие мер и соблюдение в целях
            исполнения поручения Общества требований, установленных Федеральным
            законом № 152-ФЗ.
          </I>
          <I></I>
          <I>
            Обработчик не обязан получать согласие субъекта персональных данных
            на обработку его персональных данных. Если для обработки
            персональных данных по поручению Общества необходимо получение
            согласия субъекта персональных данных, такое согласие получает
            непосредственно Общество.
          </I>
          <I></I>
          <I>
            3.7. В случаях, установленных законодательством Российской
            Федерации, Общество вправе осуществлять передачу персональных данных
            третьим лицам, в том числе без поручения таким лицам обработки
            персональных данных.
          </I>
          <I></I>
          <I>
            3.8. Если иное не предусмотрено законодательством Российской
            Федерации, Общество прекращает обработку персональных данных
            (в отношении любой из заявленных выше целей) и уничтожает
            их в случаях:
          </I>
          <I></I>
          <I>
            ликвидации Общества; реорганизации Общества, влекущей прекращение
            его деятельности; отпадения правовых оснований обработки
            персональных данных и/или достижения целей обработки персональных
            данных.
          </I>
          <I></I>
          <I>
            Конкретный порядок уничтожения персональных данных на носителях,
            содержащих персональные данные, в том числе внешних/съемных
            электронных носителях, бумажных носителях и в информационных
            системах персональных данных, определяются Обществом в своих
            внутренних документах и локальных нормативных актах.
          </I>
          <I></I>
          <I>
            3.9. Общество может собирать техническую информацию, когда
            пользователь посещает веб-сайты или использует мобильные приложения
            и услуги Общества. Сюда входит такая информация, как IP-адрес, тип
            используемого мобильного устройства, операционная система устройства
            и тип браузера, уникальный идентификатор устройства, адрес
            ссылающихся веб-сайтов, путь, по которому пользователь проходит
            через веб-сайты и мобильные приложения Общества, и так далее.
            Общество может также использовать такие технологии, как файлы
            cookie, веб-маяки и идентификаторы мобильных устройств, для сбора
            информации об использовании веб-сайтов и мобильных сервисов
            Общества. Файлы cookie позволяют Обществу предоставлять
            пользователям соответствующую информацию по мере использования ими
            веб-сайтов и мобильных сервисов Общества (например, открывать
            и загружать соответствующие страницы). Веб-маяки позволяют узнавать,
            была ли посещена определенная страница, было ли открыто электронное
            письмо или были ли эффективны рекламные баннеры на веб-сайтах
            Общества и других сайтах.
          </I>
          <I></I>
          <I>
            Общество использует данную информацию для обеспечения
            работоспособности своих веб-сайтов и мобильных приложений, для
            повышения качества оказываемых услуг, исправления ошибок и улучшения
            пользовательского опыта в целом. При этом Общество не преследует
            цели идентифицировать конкретного пользователя веб-сайтов, мобильных
            приложений и услуг Общества.
          </I>
          <I></I>
          <I>
            3.10. Общество при осуществлении обработки персональных данных:
            принимает меры, необходимые и достаточные для обеспечения выполнения
            требований законодательства Российской Федерации, внутренних
            документов и локальных нормативных актов Общества в области
            персональных данных; принимает правовые, организационные
            и технические меры для защиты персональных данных от неправомерного
            или случайного доступа к ним, уничтожения, изменения, блокирования,
            копирования, предоставления, распространения персональных данных,
            а также от иных неправомерных действий в отношении персональных
            данных; назначает лицо, ответственное за организацию обработки
            персональных данных в Обществе; издает внутренние документы,
            определяющие политику Общества в отношении обработки персональных
            данных, локальные акты по вопросам обработки персональных данных,
            а также локальные акты, устанавливающие процедуры, направленные
            на предотвращение и выявление нарушений законодательства Российской
            Федерации, устранение последствий таких нарушений; осуществляет
            ознакомление работников Общества, его филиалов, представительств
            и структурных подразделений, непосредственно осуществляющих
            обработку персональных данных, с положениями законодательства
            Российской Федерации, внутренних документов и локальных нормативных
            актов Общества в области персональных данных, в том числе
            с требованиями к защите персональных данных, и обучение указанных
            работников; проводит регулярные обязательные тренинги для своих
            работников по вопросам персональных данных; осуществляет внутренний
            контроль и (или) аудит соответствия обработки персональных данных
            требованиям законодательства Российской Федерации и принятым
            в соответствии с ним нормативным правовым актам, иным требованиям
            к защите персональных данных, настоящей Политике, внутренним
            документам и локальным нормативным актам Общества в области
            персональных данных; публикует или иным образом обеспечивает
            неограниченный доступ к настоящей Политике; прекращает обработку
            персональных данных и уничтожает их в случаях, предусмотренных
            законодательством Российской Федерации; совершает иные действия,
            предусмотренные законодательством Российской Федерации в области
            персональных данных.
          </I>
        </Div6>
      </Text1>
      <Text1>
        <Div>4. Права субъекта персональных данных</Div>
        <Div6>
          <I>
            В течение срока, на который было дано согласие на обработку
            персональных данных
          </I>
          <I></I>
          <I>
            Лицо, персональные данные которого обрабатываются Обществом, имеет:
            право на отзыв ранее данного им согласия на обработку персональных
            данных*; право на получение информации, касающейся обработки
            персональных данных; право требовать уточнения своих персональных
            данных, их блокирования или уничтожения, если персональные данные
            являются неполными, устаревшими, недостоверными, незаконно
            полученными, не являются необходимыми для заявленной цели обработки,
            а также требовать прекращения обработки персональных данных, если
            цель такой обработки достигнута Обществом. * Согласно ч. 2 ст. 9,
            ч. 4 и 5 ст. 21 Федерального закона «О персональных данных» Общество
            вправе продолжить обработку персональных данных при наличии иных
            правовых оснований.
          </I>
          <I></I>
          <I>
            Если иной порядок взаимодействия Общества и субъекта персональных
            данных не предусмотрен соответствующим документом между ними
            (например, договором или текстом согласия на обработку персональных
            данных), для реализации указанных прав субъекту персональных данных
            необходимо направить Обществу заявление: в виде электронного
            документа и подписанное электронной подписью — на электронную почту
            penat. partners@gmail. com.
          </I>
          <I></I>
          <I>
            Такое заявление должно в обязательном порядке содержать описание
            требований субъекта персональных данных, а также следующие сведения:
          </I>
          <I></I>
          <I>
            ФИО субъекта персональных данных; номер основного документа,
            удостоверяющего личность субъекта персональных данных или его
            представителя, сведения о дате выдачи указанного документа
            и выдавшем его органе ЛИБО иные данные, позволяющие однозначно
            идентифицировать субъекта персональных данных; сведения,
            подтверждающие участие субъекта персональных данных в отношениях
            с Обществом, ЛИБО сведения, иным способом подтверждающие факт
            обработки персональных данных Обществом; подпись субъекта
            персональных данных или его представителя. Субъект персональных
            данных также вправе обжаловать действия (бездействия) и решения
            Общества, нарушающие его права при обработке персональных данных,
            в уполномоченный орган по защите прав субъектов персональных данных
            (Роскомнадзор) и в суд в порядке, установленном законодательством
            Российской Федерации.
          </I>
        </Div6>
      </Text1>
      <Text1>
        <Div>
          5. Сведения о реализуемых требованиях к защите персональных данных
        </Div>
        <Div6>
          <I>
            Общество при обработке персональных данных принимает необходимые
            правовые, организационные и технические меры для защиты персональных
            данных от неправомерного или случайного доступа к ним, уничтожения,
            изменения, блокирования, копирования, предоставления,
            распространения персональных данных, а также от иных неправомерных
            действий в отношении персональных данных. Общество регулярно
            пересматривает и актуализирует принимаемые меры для обеспечения
            наилучшей защищенности обрабатываемых персональных данных — такие
            меры описываются в настоящей Политике, внутренних документах
            и локальных нормативных актах Общества.
          </I>
          <I></I>
          <I>
            К таким мерам, в частности, относится: разработка моделей угроз;
            определение угроз безопасности персональных данных при их обработке
            в информационных системах персональных данных; применение
            организационных и технических мер по обеспечению безопасности
            персональных данных при их обработке в информационных системах
            персональных данных, необходимых для выполнения требований к защите
            персональных данных, исполнение которых обеспечивает установленные
            Правительством РФ уровни защищенности персональных данных;
            применение прошедших в установленном порядке процедуру оценки
            соответствия средств защиты информации; оценка эффективности
            принимаемых мер по обеспечению безопасности персональных данных
            до ввода в эксплуатацию информационной системы персональных данных;
            обнаружение фактов несанкционированного доступа к персональным
            данным и принятием мер; восстановление персональных данных,
            модифицированных или уничтоженных вследствие несанкционированного
            доступа к ним; установление правил доступа к персональным данным,
            обрабатываемым в информационной системе персональных данных, а также
            обеспечением регистрации и учета всех действий, совершаемых
            с персональными данными в информационной системе персональных
            данных; контроль за принимаемыми мерами по обеспечению безопасности
            персональных данных и уровня защищенности информационных систем
            персональных данных; учет машинных носителей персональных данных;
            организация пропускного и внутриобъектового режимов на территории
            Общества; размещение технических средств обработки персональных
            данных в пределах охраняемой территории; поддержание технических
            средств охраны, сигнализации в постоянной готовности; проведение
            мониторинга действий пользователей, проведение разбирательств
            по фактам нарушения требований безопасности персональных данных.
          </I>
        </Div6>
      </Text1>
    </HeaderFrameRoot>
  );
};

export default HeaderFrameMobile;
