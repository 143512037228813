import styled from "styled-components";

const Div = styled.div`
  align-self: stretch;
  position: relative;
  font-weight: 500;
`;
const I = styled.p`
  margin: 0;
  margin-bottom: 15px;
`;
const I6Container = styled.div`
  width: 613.5px;
  position: relative;
  font-weight: 300;
  display: inline-block;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;
const Text1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0px;
`;
const Div1 = styled.div`
  width: 613.5px;
  height: 722px;
  position: relative;
  font-weight: 300;
  display: inline-block;
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 1280px;
  }
`;
const Li = styled.li`
  margin-bottom: 12px;
`;
const Ol = styled.ol`
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-2xl);
`;
const Div2 = styled.div`
  width: 613.5px;
  height: 932px;
  position: relative;
  font-weight: 300;
  display: inline-block;
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 1532px;
  }
`;
const HeaderFrameRoot = styled.div`
  width: 655px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-2xl) var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  gap: 31px 0px;
  max-width: 100%;
  text-align: left;
  font-size: var(--footer-size);
  color: var(--color-black);
  font-family: var(--footer);
  @media screen and (max-width: 750px) {
    gap: 31px 0px;
  }
  @media screen and (max-width: 450px) {
    padding: 0px 20px var(--padding-5xs) 20px;
    display: none;
  }
`;

const HeaderFrame = () => {
  return (
    <HeaderFrameRoot>
      <Text1>
        <Div>1. Назначение и область действия документа</Div>
        <I6Container>
          <I>
            1.1. Политика ООО «Интернет Решения» (ОГРН 1027739244741, ИНН
            7704217370, 123112, Россия, г. Москва, Пресненская наб., д. 10,
            эт. 41, пом. I, ком. 6) (далее по тексту также — «Общество»)
            в отношении обработки персональных данных (далее по тексту также —
            «Политика») определяет позицию и намерения Общества в области
            обработки и защиты персональных данных, с целью соблюдения и защиты
            прав и свобод каждого человека и, в особенности, права
            на неприкосновенность частной жизни, личную и семейную тайну, защиту
            своей чести и доброго имени.
          </I>
          <I></I>
          <I>
            1.2. Политика неукоснительно исполняется руководителями
            и работниками всех структурных подразделений, филиалов
            и представительств Общества.
          </I>
          <I></I>
          <I>
            1.3. Действие Политики распространяется на все персональные данные
            субъектов, обрабатываемые в Обществе с применением средств
            автоматизации и без применения таких средств.
          </I>
          <I></I>
          <I>
            1.4. К настоящей Политике имеет доступ любой субъект персональных
            данных, в том числе с использованием сети «Интернет».
          </I>
          <I></I>
          <I>
            1.5. Общество периодически актуализирует настоящую Политику и вправе
            в одностороннем порядке в любой момент изменять ее условия. Общество
            рекомендует регулярно проверять содержание настоящей Политики
            на предмет ее возможных изменений.
          </I>
          <I></I>
          <I>
            Если иное не предусмотрено Политикой, все вносимые в нее изменения
            вступают в силу с даты, указанной в Политике.
          </I>
          <I></I>
          <I>
            1.6. Во всем ином, что не предусмотрено настоящей Политикой,
            Общество руководствуется положениями действующего законодательства
            Российской Федерации.
          </I>
        </I6Container>
      </Text1>
      <Text1>
        <Div>2. Термины</Div>
        <Div1>
          <I>
            2.1. Персональные данные — любая информация, относящаяся к прямо или
            косвенно определенному или определяемому физическому лицу (субъекту
            персональных данных). К такой информации, в частности, можно
            отнести: ФИО, год, месяц, дата и место рождения, адрес, сведения
            о семейном, социальном, имущественном положении, сведения
            об образовании, профессии, доходах, а также другую информацию,
            позволяющую по совокупности определить (идентифицировать) субъекта
            персональных данных.
          </I>
          <I></I>
          <I>
            2.2. Обработка персональных данных — любое действие (операция) или
            совокупность действий (операций), совершаемых с использованием
            средств автоматизации или без использования таких средств
            с персональными данными, включая сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение персональных
            данных.
          </I>
          <I></I>
          <I>
            2.3. Субъект персональных данных — физическое лицо, чьи персональные
            данные обрабатываются.
          </I>
          <I></I>
          <I>
            2.4. Оператор — лицо, самостоятельно или совместно с иными лицами
            организующее и (или) осуществляющее обработку персональных данных,
            а также определяющие цели обработки персональных данных, состав
            персональных данных, подлежащих обработке, действия (операции),
            совершаемые с персональными данными. Для целей настоящей Политики
            Общество, обрабатывая персональные данные, является оператором, если
            иное прямо не указано в Политике.
          </I>
          <I></I>
          <I>
            2.5. Обработчик — любое лицо, которое на основании договора
            с оператором осуществляет обработку персональных данных по поручению
            такого оператора, действуя от имени и (или) в интересах последнего
            при обработке персональных данных. Оператор несет ответственность
            перед субъектом персональных данных за действия или бездействия
            обработчика. Обработчик несет ответственность перед оператором.
          </I>
          <I></I>
          <I>
            2.6. Прочие термины используются в настоящей Политике в соответствии
            со значениями, определяемыми действующим законодательством
            Российской Федерации, если иное прямо не указано в Политике.
          </I>
        </Div1>
      </Text1>
      <Text1>
        <Div>3. Порядок и условия обработки персональных данных</Div>
        <Div2>
          <I>
            3.1. Под безопасностью персональных данных Общество понимает
            защищенность персональных данных от неправомерного или случайного
            доступа к ним, уничтожения, изменения, блокирования, копирования,
            предоставления, распространения персональных данных, а также от иных
            неправомерных действий в отношении персональных данных и принимает
            необходимые правовые, организационные и технические меры для защиты
            персональных данных.
          </I>
          <I></I>
          <I>
            3.2. Обработка и обеспечение безопасности персональных данных
            в Обществе осуществляется в соответствии с требованиями Конституции
            Российской Федерации, Федерального закона № 152-ФЗ «О персональных
            данных», подзаконных актов, других определяющих случаи и особенности
            обработки персональных данных законов Российской Федерации, а также
            руководящих и методических документов Правительства Российской
            Федерации, Минцифры России, Роскомнадзора, ФСТЭК России и ФСБ
            России.
          </I>
          <I></I>
          <I>
            3.3. При обработке персональных данных Общество придерживается
            следующих принципов:
          </I>
          <I></I>
          <Ol>
            <Li>законности и справедливости;</Li>
            <Li>
              ограничения обработки персональных данных достижением конкретных,
              заранее определенных и законных целей;
            </Li>
            <Li>
              недопущения обработки персональных данных, несовместимой с целями
              сбора персональных данных;
            </Li>
            <Li>
              недопущения объединения баз данных, содержащих персональные
              данные, обработка которых осуществляется в целях, несовместимых
              между собой;
            </Li>
            <Li>
              соответствия содержания и объема обрабатываемых персональных
              данных заявленным целям обработки;
            </Li>
            <Li>
              недопущения избыточности обрабатываемых персональных данных
              по отношению к заявленным целям обработки;
            </Li>
            <Li>
              обеспечения точности персональных данных, их достаточности,
              а в необходимых случаях и актуальности по отношению к целям
              обработки персональных данных, а также принятия мер по удалению
              или уточнению неполных или неточных данных;
            </Li>
            <Li>
              прозрачности обработки персональных данных: субъекту персональных
              данных может предоставляться соответствующая информация,
              касающаяся обработки его персональных данных;
            </Li>
            <Li>
              осуществления хранения персональных данных в форме, позволяющей
              определить субъекта персональных данных, не дольше, чем этого
              требуют заявленные цели обработки персональных данных.
            </Li>
          </Ol>
          <I>&nbsp;</I>
          <I>
            3.4. Общество обрабатывает персональные данные на следующих
            условиях:
          </I>
        </Div2>
      </Text1>
    </HeaderFrameRoot>
  );
};

export default HeaderFrame;
